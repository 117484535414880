<template>
  <b-row>
    <b-col
      md="6"
      sm="12"
      class="text-center"
    >
      <b-avatar
        class="mb-1"
        variant="light-primary"
        size="45"
      >
        <feather-icon
          size="21"
          icon="ActivityIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getDeliveredCarsCount.sales | toNumber }}
        </h2>
        <span>Sıfır Satış</span>
      </div>
    </b-col>
    <b-col
      md="6"
      sm="12"
      class="text-center"
    >
      <b-avatar
        class="mb-1"
        variant="light-danger"
        size="45"
      >
        <feather-icon
          size="21"
          icon="EyeIcon"
        />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ getDeliveredCarsCount.i2sales | toNumber }}
        </h2>
        <span>İkinci El</span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BAvatar } from 'bootstrap-vue'

export default {
  name: 'DeliveredCars',
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  computed: {
    getDeliveredCarsCount() {
      return this.$store.getters['crmDashboard/getDeliveredCarsCounts']
    },
  },
}
</script>

<style scoped>

</style>
