<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    :variant="variant"
    :opacity="opacity"
    :blur="blur"
  >
    <div
      class="cursor-pointer"
      @click="$router.push('/marketing/campaign-sms')"
    >
      <statistic-card
        :statistic="smsCampaigns.users"
        :totals="smsCampaigns.totals"
        :icon="smsCampaigns.icon"
        :statistic-title="smsCampaigns.label"
        :color="smsCampaigns.variant"
      />
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import statisticCard from '@/views/Dashboard/components/StatisticCard.vue'

export default {
  name: 'SmsCampaigns',
  components: {
    BOverlay,
    statisticCard,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
    }
  },
  computed: {
    smsCampaigns() {
      return this.$store.getters['smsCampaignsDashboard/dashboard']
    },
    loading() {
      return this.$store.getters['smsCampaignsDashboard/loading']
    },
    getParams() {
      return this.$store.getters['dashboardFilter/getParams']
    },
  },
  watch: {
    getParams: {
      deep: true,
      handler() {
        this.getData()
      },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('smsCampaignsDashboard/getData', {
        sdate: this.getParams.sdate,
        edate: this.getParams.edate,
      })
    },
  },
}
</script>
