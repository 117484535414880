<template>
  <div>
    <dashboard />
    <b-modal
      v-model="modalData.status"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      title="Medusa | Sürüm Güncellemesi"
      size="lg"
    >
      <div class="text-center font-weight-bold">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <FeatherIcon
              icon="InfoIcon"
              size="24"
            />
            <div class="font-weight-bolder">
              Sürüm güncellemesi yayınlandı!
            </div>
            <div class="px-5">
              <p>
                {{ modalData.notes }}
              </p>
              <div class="font-small-3">
                <p>
                  Windows kullanıcıları: <kbd class="bg-light-primary">CTRL</kbd> + <kbd class="bg-light-primary">SHIFT</kbd> + <kbd class="bg-light-primary">R</kbd>
                </p>
                <p>
                  Mac kullanıcıları: <kbd class="bg-light-primary">CMD</kbd> + <kbd class="bg-light-primary">SHIFT</kbd> + <kbd class="bg-light-primary">R</kbd>
                </p>
              </div>
              <b-button
                variant="primary"
                pill
                class="my-1"
                @click="refreshPage"
              >
                Şimdi Yenile
              </b-button>
            </div>
          </div>
          <div
            class="bg-primary rounded-bottom font-small-2 text-white"
            style="padding: 5px 0"
          >
            Mevcut sürüm {{ modalData.current }} <FeatherIcon icon="ArrowRightIcon" /> Yeni Sürüm {{ modalData.version }}
            <br>
            {{ moment(modalData.updated).format('DD.MM.YYYY H:mm') }}
          </div>
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BAlert, BButton } from 'bootstrap-vue'
import { getMessaging, getToken } from 'firebase/messaging'
import Dashboard from '@/views/Dashboard/Index.vue'

export default {
  components: {
    Dashboard,
    BModal,
    BAlert,
    BButton,
  },
  data() {
    return {
      fcmToken: null, // Tokeni saklamak için bir data alanı ekleyin
      modalData: {
        status: false,
        current: null,
        version: null,
        updated: null,
        notes: null,
      },
    }
  },
  mounted() {
    this.getTokenAndSave()
    this.versionControl()
  },
  methods: {
    async versionControl() {
      const { title } = document
      const version = title.split(' - v2.0.')[1]
      this.$store.dispatch('app/checkVersion')
        .then(response => {
          if (response.version > version) {
            this.modalData.status = true
            this.modalData.current = `v2.0.${version}`
            this.modalData.version = `v2.0.${response.version}`
            this.modalData.updated = response.updated
            this.modalData.notes = response.notes
          }
        })
    },
    refreshPage() {
      window.location.reload(true)
    },
    async getTokenAndSave() {
      const messaging = getMessaging()
      try {
        const currentToken = await getToken(messaging, {
          vapidKey: 'BO-JQXNGBIIpzsqAJJ87hEBiu1qw6ItuUQpl8yJJ6HWcZDoTknBx4mrVDE6mkyFq91Vkz3HXNgD6TEQ7C92Szls',
        })

        if (currentToken) {
          this.fcmToken = currentToken
          this.$store.dispatch('userTokens/saveToken', {
            fcmToken: currentToken,
          })
        } else {
          console.log("Kullanıcının cihaz token'ı yok.")
        }
      } catch (error) {
        console.error("Cihaz token'ı alınamadı:", error)
      }
    },
  },
}
</script>
