<template>
  <div>
    <b-row>
      <b-col md="4">
        <customers />
      </b-col>
      <b-col md="4">
        <sales />
      </b-col>
      <b-col md="4">
        <i2-sales />
      </b-col>
      <b-col md="3">
        <alerts />
      </b-col>
      <b-col md="3">
        <requests />
      </b-col>
      <b-col md="3">
        <birthday-sms />
      </b-col>
      <b-col md="3">
        <sms-campaigns />
      </b-col>
      <b-col md="12">
        <alert-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import customers from '@/views/Dashboard/Common/Counters/Customers.vue'
import sales from '@/views/Dashboard/Common/Counters/Sales.vue'
import i2Sales from '@/views/Dashboard/Common/Counters/i2Sales.vue'
import alerts from '@/views/Dashboard/Common/Counters/Alerts.vue'
import requests from '@/views/Dashboard/Common/Counters/Requests.vue'
import birthdaySms from '@/views/Dashboard/Common/Counters/BirthdaySms.vue'
import smsCampaigns from '@/views/Dashboard/Common/Counters/SmsCampaigns.vue'
import AlertList from '@/views/Dashboard/Common/Tables/AlertList.vue'

export default {
  name: 'Manager',
  components: {
    BRow,
    BCol,
    alerts,
    requests,
    AlertList,
    sales,
    i2Sales,
    customers,
    birthdaySms,
    smsCampaigns,
  },
}
</script>
