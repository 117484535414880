<template>
  <div>
    <b-row>
      <b-col md="4">
        <customers />
      </b-col>
      <b-col md="4">
        <interviews />
      </b-col>
      <b-col md="4">
        <sales />
      </b-col>
      <b-col md="4">
        <i2-sales />
      </b-col>
      <b-col md="4">
        <alerts />
      </b-col>
      <b-col md="4">
        <requests />
      </b-col>
      <b-col md="3">
        <expertise />
      </b-col>
      <b-col md="3">
        <spare-part-orders />
      </b-col>
      <b-col md="3">
        <rental-contracts />
      </b-col>
      <b-col md="3">
        <realty-sales />
      </b-col>
      <b-col md="3">
        <service />
      </b-col>
      <b-col md="3">
        <insurance-renewals />
      </b-col>
      <b-col md="3">
        <birthday-sms />
      </b-col>
      <b-col md="3">
        <sms-campaigns />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="6">
        <interviews-list />
      </b-col>
      <b-col md="6">
        <alert-list />
      </b-col>
      <b-col md="6">
        <service-list />
      </b-col>
      <b-col md="6">
        <sales-list />
      </b-col>
      <b-col md="6">
        <i2-sales-list />
      </b-col>
      <b-col md="6">
        <rental-contract-list />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import customers from '@/views/Dashboard/Common/Counters/Customers.vue'
import interviews from '@/views/Dashboard/Common/Counters/Interviews.vue'
import alerts from '@/views/Dashboard/Common/Counters/Alerts.vue'
import requests from '@/views/Dashboard/Common/Counters/Requests.vue'
import sales from '@/views/Dashboard/Common/Counters/Sales.vue'
import i2Sales from '@/views/Dashboard/Common/Counters/i2Sales.vue'
import expertise from '@/views/Dashboard/Common/Counters/Expertise.vue'
import sparePartOrders from '@/views/Dashboard/Common/Counters/SparePartOrders.vue'
import rentalContracts from '@/views/Dashboard/Common/Counters/RentalContracts.vue'
import realtySales from '@/views/Dashboard/Common/Counters/RealtySales.vue'
import service from '@/views/Dashboard/Common/Counters/Services.vue'
import insuranceRenewals from '@/views/Dashboard/Common/Counters/InsuranceRenewals.vue'
import birthdaySms from '@/views/Dashboard/Common/Counters/BirthdaySms.vue'
import smsCampaigns from '@/views/Dashboard/Common/Counters/SmsCampaigns.vue'
import InterviewsList from '@/views/Dashboard/Common/Tables/InterviewsList.vue'
import AlertList from '@/views/Dashboard/Common/Tables/AlertList.vue'
import ServiceList from '@/views/Dashboard/Common/Tables/ServiceList.vue'
import SalesList from '@/views/Dashboard/Common/Tables/SalesList.vue'
import i2SalesList from '@/views/Dashboard/Common/Tables/i2SalesList.vue'
import RentalContractList from '@/views/Dashboard/Common/Tables/RentalContractList.vue'

export default {
  name: 'Manager',
  components: {
    BRow,
    BCol,
    customers,
    requests,
    alerts,
    interviews,
    sales,
    i2Sales,
    expertise,
    sparePartOrders,
    rentalContracts,
    realtySales,
    service,
    insuranceRenewals,
    birthdaySms,
    smsCampaigns,
    InterviewsList,
    AlertList,
    ServiceList,
    SalesList,
    i2SalesList,
    RentalContractList,
  },
}
</script>
